export const PATHS = {
  ACCOUNT_PROFILE: 'account',
  CHANGE_PASSWORD: 'change-password',
  EXPLORE: 'explore',
  FAVORITES: 'favorites',
  FORGOT_PASSWORD: 'forgot-password',
  LANDING: 'home',
  LOGIN: 'login',
  ONBOARDING: 'join',
  RESET_PASSWORD: 'password-reset',
  RESTAURANT_PROFILE: 'profile',
  RESTAURANTS: 'restaurants',
  SETTINGS: 'settings',
  SIGN_UP: 'signup',
};

export const BOTTOM_NAV_PATHS = [
  PATHS.ACCOUNT_PROFILE,
  PATHS.CHANGE_PASSWORD,
  PATHS.EXPLORE,
  PATHS.FAVORITES,
  PATHS.FORGOT_PASSWORD,
  PATHS.LANDING,
  PATHS.LOGIN,
  PATHS.ONBOARDING,
  PATHS.RESET_PASSWORD,
  PATHS.RESTAURANT_PROFILE,
  PATHS.RESTAURANTS,
  PATHS.SETTINGS,
  PATHS.SIGN_UP
];
