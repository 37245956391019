import { FavoritesInterface } from '../interfaces/FavoritesInterface';
import { mobileApiClient } from './apiClient';
import { GET_FAVORITED_MENU_ITEM, GET_FAVORITES, SAVE_MENU_ITEM, SAVE_RESTAURANT } from '../constants/endpoints';
import { AxiosResponse } from 'axios';
import { MenuItemInterface } from '../interfaces/MenuItemInterface';

export const getFavorites = async (): Promise<FavoritesInterface> => {
  return mobileApiClient
    .get(GET_FAVORITES)
    .then((response: AxiosResponse<FavoritesInterface>) => {
      return response.data;
    });
};

export const getFavoritedMenuItem = async (itemID: number): Promise<MenuItemInterface> => {
  return mobileApiClient
    .get(`${GET_FAVORITED_MENU_ITEM}/${itemID}`)
    .then((response: AxiosResponse<MenuItemInterface>) => {
      return response.data;
    });
};

export const updateMenuItemSaveStatus = async (menuItemID: number, save: boolean) => {
  await mobileApiClient.post(SAVE_MENU_ITEM, { menuItemID, save, });
};

export const updateRestaurantSaveStatus = async (restaurantUrlID: string, save: boolean) => {
  await mobileApiClient.post(SAVE_RESTAURANT, { restaurantUrlID, save });
};

