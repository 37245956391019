import {
  createContext,
  useState,
  useEffect,
  ReactElement,
  useMemo,
  useContext,
  useCallback
} from 'react';
import Cookies from 'universal-cookie';
import { GetUserInfoResponse, UserInterface } from '../interfaces/UserInterface';
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN, COOKIE_RESTAURANT_ID } from '../constants/cookie-constants';
import { deleteUserAccount, getUserInfo } from '../api/user';
import { logout } from '../api/auth';
import { useNavigate } from 'react-router-dom';
import { storeAccessToken, storeRefreshToken } from '../utils/auth';
import { PATHS } from '../constants/taptab-paths';

interface UserContextInterface {
  authenticated: boolean | null;
  authenticateUser: (access: string, refresh: string, authenticatedUser: UserInterface) => void;
  deleteAccount: () => void;
  fetchUser: () => Promise<UserInterface>;
  logoutUser: () => void;
  removeTokens: () => void;
  sessionExpired: () => void;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  setUser: (user: UserInterface) => void;
  user: UserInterface | null;
}

interface UserProviderInterface {
  children: ReactElement | ReactElement[];
}

const defaultUser: UserInterface = {
  userID: -1,
  firstName: '',
  lastName: '',
  email: '',
  emailIsVerified: false,
}

const UserContext = createContext<UserContextInterface>({
  authenticated: null,
  authenticateUser: () => {},
  deleteAccount: () => {},
  fetchUser: async () => defaultUser,
  logoutUser: () => {},
  removeTokens: () => {},
  sessionExpired: () => {},
  setAccessToken: () => {},
  setRefreshToken: () => {},
  setUser: () => {},
  user: null,
});

const UserProvider = ({ children }: UserProviderInterface) => {
  const [cookies, setCookies] = useState(new Cookies());
  const [user, setUser] = useState<UserInterface | null>(null);

  const navigate = useNavigate();

  const getAccessToken = useCallback(() => cookies.get(COOKIE_ACCESS_TOKEN), [cookies]);
  const getRefreshToken = useCallback(() => cookies.get(COOKIE_REFRESH_TOKEN), [cookies]);

  const fetchUser = useCallback(async (): Promise<UserInterface> => {
    let userWithInfo: UserInterface;
    const response: GetUserInfoResponse = await getUserInfo();
    userWithInfo = {
      ...(user != null && { userID: user?.userID }),
      ...(user != null && { emailIsVerified: user?.emailIsVerified }),
      email: response?.email,
      firstName:  response.firstName,
      lastName: response.lastName,
      phone: response.phone,
      imageURL: response.imageURL,
    };
    setUser(userWithInfo);
    return userWithInfo;
  }, [user]);

  const sessionExpired = useCallback(
    () => {
      setUser(null);
      setAuthenticated(false);
    },
    []
  );


  const setAccessToken = useCallback(
    (token: string) => {
      storeAccessToken(token);
      setCookies(cookies);
    },
    [cookies]
  );

  const setRefreshToken = useCallback(
    (token: string) => {
      storeRefreshToken(token);
      setCookies(cookies);
    },
    [cookies]
  );

  const authenticateUser = useCallback( (access: string, refresh: string, authenticatedUser: UserInterface) => {
    setAccessToken(access);
    setRefreshToken(refresh);
    setUser(authenticatedUser);
    setAuthenticated(true);
  }, [setAccessToken, setRefreshToken]);

  const removeTokens = useCallback(() => {
    cookies.remove(COOKIE_ACCESS_TOKEN, { path: '/' });
    cookies.remove(COOKIE_REFRESH_TOKEN, { path: '/' });
    setCookies(cookies);
  }, [cookies]);

  const deleteAccount = useCallback(async () => {
    await deleteUserAccount();

    removeTokens();
    const restaurantID = cookies.get(COOKIE_RESTAURANT_ID);
    if (restaurantID) {
      navigate(`/${restaurantID}`);
    } else {
      navigate(`/${PATHS.LANDING}`);
    }
  }, [cookies, navigate, removeTokens]);

  const logoutUser = useCallback(async () => {
    try {
      await logout();
    } catch (err) {
      console.error('Failed to logout user:', err);
    }

    removeTokens();
    setAuthenticated(false);
  }, [removeTokens]);

  const isAuthenticated = useCallback(() => !!getAccessToken() || !!getRefreshToken(), [getAccessToken, getRefreshToken]);

  const [authenticated, setAuthenticated] = useState(isAuthenticated());

  useEffect(() => {
    const cookieChangeListener = () => {
      setAuthenticated(isAuthenticated());
    };

    cookies.addChangeListener(cookieChangeListener);
    setCookies(cookies);
    return () => {
      cookies.removeChangeListener(cookieChangeListener);
      setCookies(cookies);
    };
  }, [cookies, isAuthenticated]);

  const providerValue = useMemo(
    () => ({ authenticated, authenticateUser, deleteAccount, fetchUser, logoutUser, removeTokens, sessionExpired, setAccessToken, setRefreshToken, setUser, user }),
    [authenticated, authenticateUser, deleteAccount, fetchUser, logoutUser, removeTokens, sessionExpired, setAccessToken, setRefreshToken, setUser, user]
  );

  return <UserContext.Provider value={providerValue}>{children} </UserContext.Provider>;
};

const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext must be used within the UserProvider component`);
  }
  return context;
};

export { UserProvider, useUserContext };
