import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json') as Resource
    },
    es: {
      translations: require('./locales/es/translations.json') as Resource
    },
    fr: {
      translations: require('./locales/fr/translations.json') as Resource
    },
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'es', 'fr'];

export default i18n;
