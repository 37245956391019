import { mobileApiClient } from "./apiClient";
import { VALIDATE_CONSUMER } from '../constants/endpoints';

export const validateConsumer = async (origin, key, restaurantUrlID) => {
    return mobileApiClient
        .post(VALIDATE_CONSUMER,{ origin, key, restaurantUrlID })
        .then(() => {
            return true;
        })
        .catch(() => {
            // return false if there is an error (Unauthorized Consumer)
            return false;
        });
};
