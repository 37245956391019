import React from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import TapTabWhiteIcon from "../../assets/svgs/TapTabWhiteIcon";
import ReactDOM from "react-dom";

const LoadingOverlay = () => {
  return (
    ReactDOM.createPortal(
    <div className="loading-overlay-container">
      <div className="loading-overlay-content">
        <TapTabWhiteIcon width="70" height="70" />
        <LoadingSpinner size={85} className="loading-overlay-spinner" />
      </div>
    </div>,
      document.querySelector('#loading-overlay-root')
    )
  );
};

export default LoadingOverlay;
