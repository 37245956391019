import React from "react";

import { Oval } from 'react-loader-spinner';

const LoadingSpinner = ({ primary = 'white', secondary = 'white', size = 100, className = "" }) => (
  <Oval
    wrapperClass={className}
    ariaLabel="loading-indicator"
    height={size}
    width={size}
    strokeWidth={5}
    strokeWidthSecondary={1}
    color={primary}
    secondaryColor={secondary}
  />
);

export default LoadingSpinner;
