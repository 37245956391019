import { mobileApiClient } from './apiClient';
import { CreateAccountRequest, CreateAccountResponse } from '../interfaces/CreateAccountInterface';
import { AxiosResponse } from 'axios';
import { LoginRequest, LoginResponse } from '../interfaces/LoginInterface';
import {
  APPLE_LOGIN,
  FORGOT_PASSWORD,
  GOOGLE_LOGIN,
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  SIGNUP
} from '../constants/endpoints';
import { RefreshTokenResponse } from '../interfaces/RefreshTokenInterface';

export const appleAuth = async (authorizationToken: string, firstName?: string, lastName?: string): Promise<LoginResponse> => {
  return mobileApiClient
    .post(APPLE_LOGIN,
      {
        authorizationToken,
        ...(firstName && { firstName }),
        ...(lastName && { lastName })
      })
      .then((response: AxiosResponse<LoginResponse>) => {
        return response.data;
      });
};

export const createAccount = async ({ email, pwd, firstName, lastName }: CreateAccountRequest): Promise<CreateAccountResponse> => {
  return mobileApiClient
    .post(SIGNUP,
      { email, password: pwd, firstName, lastName
      })
    .then((response: AxiosResponse<CreateAccountResponse>) => {
      return response.data;
    });
};

export const forgotPassword = async (email: string): Promise<void> => {
  await mobileApiClient.post(FORGOT_PASSWORD, { email });
};

export const googleAuth = async (authorizationCode: string, firstName?: string, lastName?: string): Promise<LoginResponse> => {
  return mobileApiClient
    .post(GOOGLE_LOGIN, {
      authorizationCode,
      ...(firstName && { firstName }),
      ...(lastName && { lastName })
    })
    .then((response: AxiosResponse<LoginResponse>) => {
      return response.data;
    });
};

export const login = async ({ email, pwd }: LoginRequest): Promise<LoginResponse> => {
  return mobileApiClient
    .post(LOGIN, { email, password: pwd })
    .then((response: AxiosResponse<LoginResponse>) => {
      return response.data;
    });
};

export const logout = async (): Promise<void> => {
  await mobileApiClient.delete(LOGOUT);
};

export const resetPassword = async (newPassword: string, resetCode: string): Promise<void> => {
  await mobileApiClient.post(RESET_PASSWORD, { newPassword, resetCode });
};

export const sessionRefresh = async (token: string): Promise<RefreshTokenResponse> => {
  return mobileApiClient
    .post(REFRESH_TOKEN, undefined, { headers: { authorization: token }})
    .then((response: AxiosResponse<CreateAccountResponse>) => {
      return response.data;
  });
};
