import { useMediaQuery } from 'react-responsive';

export default function useResponsive() {
  const isTabletOrMobile = useMediaQuery({ minWidth: 0, maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return {
    isTabletOrMobile,
    isDesktop,
  };
};
