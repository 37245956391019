import { useState, useEffect } from 'react';
import { eventEmitter } from '../utils/events';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import { PATHS } from '../constants/taptab-paths';
import { LOGOUT_SOURCE } from '../constants/analytics-constants';
import { useFirebaseAnalytics } from './useFirebaseAnalytics';

const useSessionExpired = (): { showSessionExpiredModal: boolean, confirmSessionExpiredModal: () => void } => {
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { sessionExpired } = useUserContext();
  const { logLogoutEvent } = useFirebaseAnalytics();

  useEffect(() => {
    const handleTokenRefreshFailed = () => {
      setShowSessionExpiredModal(true);
    };

    // When 'token_refresh_failed' event is fired, call handleTokenRefreshFailed
    eventEmitter.on('token_refresh_failed', handleTokenRefreshFailed);

    // Cleanup after the component unmounts
    return () => {
      eventEmitter.off('token_refresh_failed', handleTokenRefreshFailed);
    };
  }, [sessionExpired]);

  const confirmSessionExpiredModal = () => {
    setShowSessionExpiredModal(false);
    logLogoutEvent({ logoutSource: LOGOUT_SOURCE.TOKEN_EXPIRE });
    sessionExpired();
    navigate(`/${PATHS.ONBOARDING}`);
  };

  return { showSessionExpiredModal, confirmSessionExpiredModal };
};

export default useSessionExpired;
