import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useIsRestricted, useLocales } from "./hooks";
import { WidgetModeProvider } from "./context/WidgetModeContext";
import LoadingOverlay from "./components/LoadingOverlay";
import 'react-toastify/dist/ReactToastify.css';
import type { Router as RemixRouter } from "@remix-run/router";

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';

const App = () => {
  const { restricted, isWidget } = useIsRestricted();
  const { locale } = useLocales();

  const [router, setRouter] = useState<RemixRouter | null>(null);

  // Custom widget functionality implemented for the restaurant Mamazul: 1049 (restaurant id)
  const isCustomWidget = (path: string) => {
    return path.split('/')[1] === process.env.REACT_APP_CUSTOM_WIDGET;
  };

  useEffect(() => {
    if (isCustomWidget(window.location.pathname)) {
      document.documentElement.className += " app-loaded custom-widget";
    } else {
      document.documentElement.className += " app-loaded";
    }


    /**
     * Polyfill added that allows smooth scrolling behavior to work on Safari iOS
     */
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (router == null && restricted != null) {
      if (restricted) {
        import('./routes/UnauthorizedRoutes').then(({ unauthorizedRouter }) => {
          setRouter(unauthorizedRouter);
        });
      } else if (isWidget) {
        import('./routes/WidgetRoutes').then(({ widgetRouter }) => {
          setRouter(widgetRouter);
        });
      } else {
        import('./routes/DefaultRoutes').then(({ defaultRouter }) => {
          setRouter(defaultRouter);
        });
      }
    }
  }, [isWidget, restricted, router]);

  if (restricted == null || locale === '' || router == null) {
    return (
      <LoadingOverlay />
    );
  }

  return (
    <WidgetModeProvider isWidget={isWidget}>
      <RouterProvider fallbackElement={<LoadingOverlay />} router={router} />
    </WidgetModeProvider>
  );
};

export default App;
