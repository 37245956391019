import { useCallback, useEffect, useState } from 'react';
import { PATHS } from '../constants/taptab-paths';
import { LoginResponse } from '../interfaces/LoginInterface';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import { googleAuth } from '../api/auth';
import { useTranslation } from 'react-i18next';
import { buildRestaurantPath } from '../utils/generalUtils';
import { getStoredRestaurantInfo } from '../utils/cookies';
import { useFirebaseAnalytics } from './useFirebaseAnalytics';
import { updateMenuItemSaveStatus, updateRestaurantSaveStatus } from '../api/favorites';

export const useGoogleAuth = (): {
  accessCode: string,
  authenticateGoogle: (code: string, firstName?: string, lastName?: string) => void,
  googleErrorMessage: string,
  isGoogleAuthing: boolean,
  nameIsRequired: boolean,
} => {
  const [isGoogleAuthing, setIsGoogleAuthing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [accessCode, setAccessCode] = useState<string>('');
  const [nameIsRequired, setNameIsRequired] = useState<boolean>(false);

  const { logSignInGoogleSuccessEvent } = useFirebaseAnalytics();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { authenticateUser } = useUserContext();

  const authenticateGoogle = useCallback((code: string, firstName?: string, lastName?: string) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    if (accessCode !== code) {
      setAccessCode(code);
    }
    setIsGoogleAuthing(true);

    const { isSave, restaurantUrlID, menuID, menuItemID } = getStoredRestaurantInfo();

    googleAuth(code, firstName, lastName).then(async ({ accessToken, refreshToken, user }: LoginResponse) => {
      authenticateUser(accessToken, refreshToken, user);

      logSignInGoogleSuccessEvent();
      if (menuItemID && isSave === 'true') {
        return updateMenuItemSaveStatus(parseInt(menuItemID), true).catch(() => {
          // catch error and let functionality continue, considered low priority functionality
        });
      } else if (restaurantUrlID && isSave === 'true') {
        return updateRestaurantSaveStatus(restaurantUrlID, true).catch(() => {
          // catch error and let functionality continue, considered low priority functionality
        });
      }
    }).catch((err: any) => {
      if (err?.response?.status === 400) {
        if (nameIsRequired) {
          setErrorMessage(translate('sign_up_unauthorized_message') ?? 'Sorry, your account creation failed. Please check your credentials and try again.');
        }

        // if display info already true then display error
        setNameIsRequired(true);
      } else {
        setErrorMessage(translate('sign_up_unauthorized_message') ?? 'Sorry, your account creation failed. Please check your credentials and try again.');
      }
    }).finally(() => {
      setIsGoogleAuthing(false);
      if (errorMessage === '') {
        const path = buildRestaurantPath(restaurantUrlID, menuID, menuItemID);
        navigate(path ? path : `/${PATHS.LANDING}`);
      }
    });
  }, [accessCode, authenticateUser, errorMessage, logSignInGoogleSuccessEvent, nameIsRequired, navigate, translate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    // if code exists and hasn't already been stored locally
    // and not currently authenticating with google then make call
    if (code && code !== accessCode && !isGoogleAuthing) {
      authenticateGoogle(code);
    }
  }, [accessCode, authenticateGoogle, isGoogleAuthing]);

  return { accessCode, authenticateGoogle, googleErrorMessage: errorMessage, isGoogleAuthing, nameIsRequired };
};
