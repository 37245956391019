import React, { createContext, useContext, useMemo } from "react";

export const WidgetModeContext = createContext();

const WidgetModeProvider = (props) => {
  const value = useMemo(() => ({ isWidget: props.isWidget }), [props.isWidget]);

  return <WidgetModeContext.Provider value={value} {...props} />;
};

const useWidgetMode = () => {
  const context = useContext(WidgetModeContext);
  if (!context) {
    throw new Error(`useWidgetMode must be used within a WidgetModeContext`);
  }
  return context;
};

export { WidgetModeProvider, useWidgetMode };
