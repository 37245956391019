import { useState, useEffect } from 'react';
import { validateConsumer } from "../api/widgets";

/**
 * Wrapper that allows users with access to the WebMenus to use our website in their iFrame's.
 * Consumers are given a key and only their origin is allowed to access the view.
 * @returns {object}
 * - restricted - flag indicating if the user is allowed access or not
 * - isWidget - flag indicating the application is being viewed as a widget
 */
const useIsRestricted = (): { isWidget: boolean, restricted: boolean | null } => {
    const [restricted, setRestricted] = useState<boolean | null>(null);
    const [isWidget, setIsWidget] = useState<boolean>(false);

    useEffect(() => {
        let ignore = false;

        /***
         * Setting restricted to false since there are no ancestor origins meaning the user came directly to the website
         */
        if (window?.location?.ancestorOrigins?.length > 0) {
            setIsWidget(true);
            const origin = document?.referrer;
            const pathname = window?.location?.pathname;
            const restaurantID = pathname.split("/")[1];
            const parsedOrigin = origin.split("/")[2];
            const urlSearchParams = new URLSearchParams(window?.location?.search);
            const key = urlSearchParams.get("key");

            if (key) {
                validateConsumer(parsedOrigin, key, restaurantID).then((isValid: boolean) => {
                    if (!ignore) {
                        setRestricted(!isValid);
                    }
                });
            }
        } else {
            if (window.location !== window.parent.location ) {
                setIsWidget(true);
            }
            setRestricted(false);
        }

        return () => {
            ignore = true;
        }
    }, []);

    return { restricted, isWidget };
};

export default useIsRestricted;
