import { mobileApiClient } from './apiClient';
import { AxiosResponse } from 'axios';
import { GetUserInfoResponse } from '../interfaces/UserInterface';
import { CHANGE_PASSWORD, DELETE_ACCOUNT, GET_USER_INFO, UPDATE_USER_INFO } from '../constants/endpoints';

export const changePassword = async (currentPassword: string, newPassword: string) => {
  await mobileApiClient.put(CHANGE_PASSWORD, { currentPassword, newPassword });
};

export const deleteUserAccount = async () => {
  await mobileApiClient.delete(DELETE_ACCOUNT);
};

export const getUserInfo = (): Promise<GetUserInfoResponse> => {
  return mobileApiClient
    .get(GET_USER_INFO)
    .then((response: AxiosResponse<GetUserInfoResponse>) => {
      return response.data;
    });
};

export const updateUserInfo = async (firstName?: string, lastName?: string, email?: string, phone?: string) => {
  await mobileApiClient.put(UPDATE_USER_INFO, {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(email && { email }),
    ...(phone && { phone }),
  });
};
