/**
 * AUTH API
 */
const AUTH_API = '/auth';
export const FORGOT_PASSWORD = `${AUTH_API}/forgotPassword`;
export const GOOGLE_LOGIN = `${AUTH_API}/login/google`;
export const LOGIN = `${AUTH_API}/login`;
export const APPLE_LOGIN = `${LOGIN}/apple`;
export const LOGOUT = `${AUTH_API}/logout`;
export const REFRESH_TOKEN = `${AUTH_API}/refresh`;
export const RESET_PASSWORD = `${AUTH_API}/resetPassword`;
export const SIGNUP = `${AUTH_API}/signup`;

/**
 * FAVORITES API
 */
export const GET_FAVORITES = '/save';

/**
 * RESTAURANT API
 */
const RESTAURANT_API = '/restaurant';
export const GET_RESTAURANT_DETAILS = `${RESTAURANT_API}`;
export const GET_RESTAURANTS_BY_LOCATION = '/restaurants/location';
const GET_RESTAURANT_DETAILS_PATTERN = /\/restaurant\/\w+/;

export const SUBMIT_EMAIL = `${RESTAURANT_API}/email`
export const SUBMIT_FEEDBACK = `${RESTAURANT_API}/feedback`

/**
 * MENU API
 */
export const GET_MENUS = '/menus';
export const GET_MENUS_DETAILS = '/menu';
const GET_MENUS_DETAILS_PATTERN = /\/menu\/\d+/;
export const GET_FAVORITED_MENU_ITEM = '/menu/menuItem/favorite';
const GET_FAVORITED_MENU_ITEM_PATTERN = /\/menu\/menuItem\/favorite\/\d+/;
/**
 * PAGE API
 */
export const GET_PAGE_DETAILS = 'restaurant/profilePages';
/**
 * SAVE API
 */
const SAVE_API = '/save';
export const SAVE_MENU_ITEM = `${SAVE_API}/menuItem`;
export const SAVE_RESTAURANT = `${SAVE_API}/restaurant`;


/**
 * USER API
 */
const USER_API = '/user';
export const CHANGE_PASSWORD = `${USER_API}/password`;
export const DELETE_ACCOUNT = `${USER_API}/account`;
export const GET_USER_INFO = `${USER_API}/info`;
export const UPDATE_USER_INFO = `${USER_API}/info`;

/**
 * WIDGET API
 */
export const VALIDATE_CONSUMER = '/widget/validate';

/**
 * Endpoints included for auth token functionality
 */
export const ENDPOINTS_TO_INCLUDE = [
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  GET_FAVORITES,
  GET_MENUS_DETAILS_PATTERN,
  GET_FAVORITED_MENU_ITEM_PATTERN,
  GET_RESTAURANT_DETAILS_PATTERN,
  GET_USER_INFO,
  LOGOUT,
  SAVE_MENU_ITEM,
  SAVE_RESTAURANT,
  UPDATE_USER_INFO
];
