import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Hook used to determine a users preferred language using their locale.
 * Defaults to English if unexpected locale is provided or navigator {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language} is undefined
 * Upper and lowercase locales handled due to old versions of Safari on iOS <= 10.2
 * {@link https://www.localeplanet.com/icu/iso639.html}
 * @returns {object}
 * - language- calculated for user, if empty string language is not set.
 * - locale - locale retrieved from {@type Intl} object.
 */
const useLocales = (): { language: string, locale: string } => {
  const { i18n } = useTranslation();

  const [locale, setLocale] = useState<string>('');
  const [language, setLanguage] = useState<string>('');

  useEffect(() => {
    let ignore: boolean = false;

    const updateLanguage = async (lang: string): Promise<string> => {
      await i18n.changeLanguage(lang);
      return lang;
    };

    const _locale: string = navigator?.language;
    setLocale(_locale);
    switch (_locale) {
      case "fr":
      case "fr-BE":
      case "fr-be":
      case "fr-BF":
      case "fr-bf":
      case "fr-BI":
      case "fr-bi":
      case "fr-BJ":
      case "fr-bj":
      case "fr-BL":
      case "fr-bl":
      case "fr-CA":
      case "fr-ca":
      case "fr-CD":
      case "fr-cd":
      case "fr-CF":
      case "fr-cf":
      case "fr-CG":
      case "fr-cg":
      case "fr-CH":
      case "fr-ch":
      case "fr-CI":
      case "fr-ci":
      case "fr-CM":
      case "fr-cm":
      case "fr-DJ":
      case "fr-dj":
      case "fr-DZ":
      case "fr-dz":
      case "fr-FR":
      case "fr-fr":
      case "fr-GA":
      case "fr-ga":
      case "fr-GF":
      case "fr-gf":
      case "fr-GN":
      case "fr-gn":
      case "fr-GP":
      case "fr-gp":
      case "fr-GQ":
      case "fr-gq":
      case "fr-HT":
      case "fr-ht":
      case "fr-KM":
      case "fr-km":
      case "fr-LU":
      case "fr-lu":
      case "fr-MA":
      case "fr-ma":
      case "fr-MC":
      case "fr-mc":
      case "fr-MF":
      case "fr-mf":
      case "fr-MG":
      case "fr-mg":
      case "fr-ML":
      case "fr-ml":
      case "fr-MQ":
      case "fr-mq":
      case "fr-MR":
      case "fr-mr":
      case "fr-MU":
      case "fr-mu":
      case "fr-NC":
      case "fr-nc":
      case "fr-NE":
      case "fr-ne":
      case "fr-PF":
      case "fr-pf":
      case "fr-PM":
      case "fr-pm":
      case "fr-RE":
      case "fr-re":
      case "fr-RW":
      case "fr-rw":
      case "fr-SC":
      case "fr-sc":
      case "fr-SN":
      case "fr-sn":
      case "fr-SY":
      case "fr-sy":
      case "fr-TD":
      case "fr-td":
      case "fr-TG":
      case "fr-tg":
      case "fr-TN":
      case "fr-tn":
      case "fr-VU":
      case "fr-vu":
      case "fr-WF":
      case "fr-wf":
      case "fr-YT":
      case "fr-yt":
        updateLanguage('fr').then((lang: string) => { if(!ignore) setLanguage(lang) });
        break;
      case "es":
      case "es-419":
      case "es-AR":
      case "es-ar":
      case "es-BO":
      case "es-bo":
      case "es-BR":
      case "es-br":
      case "es-BZ":
      case "es-bz":
      case "es-CL":
      case "es-cl":
      case "es-CO":
      case "es-co":
      case "es-CR":
      case "es-cr":
      case "es-CU":
      case "es-cu":
      case "es-DO":
      case "es-do":
      case "es-EA":
      case "es-ea":
      case "es-EC":
      case "es-ec":
      case "es-ES":
      case "es-es":
      case "es-GQ":
      case "es-gq":
      case "es-GT":
      case "es-gt":
      case "es-HN":
      case "es-hn":
      case "es-IC":
      case "es-ic":
      case "es-MX":
      case "es-mx":
      case "es-NI":
      case "es-ni":
      case "es-PA":
      case "es-pa":
      case "es-PE":
      case "es-pe":
      case "es-PH":
      case "es-ph":
      case "es-PR":
      case "es-pr":
      case "es-PY":
      case "es-py":
      case "es-SV":
      case "es-sv":
      case "es-US":
      case "es-us":
      case "es-UY":
      case "es-uy":
      case "es-VE":
      case "es-ve":
        updateLanguage('es').then((lang: string) => { if(!ignore) setLanguage(lang) });
        break;
      case "en":
      default:
        updateLanguage('en').then((lang: string) => { if(!ignore) setLanguage(lang) });
    }

    return () => {
      ignore = true;
    }
  }, [i18n]);

  return { language, locale };
};

export default useLocales;
