import {
  COOKIE_INITIATION_METHOD,
  COOKIE_MENU_ID,
  COOKIE_MENU_ITEM_ID,
  COOKIE_RESTAURANT_ID, COOKIE_SAVE_ACTION
} from '../constants/cookie-constants';
import Cookies from 'universal-cookie';

export type ParamValue = string | null;

const cookies = new Cookies();

export const initiateRestaurantInfo = (): {
  isSave: ParamValue,
  restaurantUrlID: ParamValue,
  menuID: ParamValue,
  menuItemID: ParamValue,
  method: ParamValue
} => {
  const urlParams = new URLSearchParams(window.location.search);
  const restaurantUrlID: ParamValue = urlParams.get('restaurant');
  const menuID: ParamValue = urlParams.get('menu');
  const menuItemID: ParamValue = urlParams.get('menuItem');
  const method: ParamValue = urlParams.get('method');
  const isSave: ParamValue = urlParams.get('isSave');

  return { isSave, restaurantUrlID, menuID, menuItemID, method };
};

/**
 * Get all restaurant info stored in the cookies. If the value doesn't exist in cookies will be undefined.
 */
export const getStoredRestaurantInfo = (): {
  isSave: ParamValue,
  restaurantUrlID: ParamValue,
  menuID: ParamValue,
  menuItemID: ParamValue,
  method: ParamValue
} => {
  const restaurantUrlID: ParamValue = getValue(COOKIE_RESTAURANT_ID);
  const menuID: ParamValue = getValue(COOKIE_MENU_ID);
  const menuItemID: ParamValue = getValue(COOKIE_MENU_ITEM_ID);
  const method: ParamValue = getValue(COOKIE_INITIATION_METHOD);
  const isSave: ParamValue = getValue(COOKIE_SAVE_ACTION);

  return { isSave, restaurantUrlID, menuID, menuItemID, method };
};

/**
 * Removes information related to menu information from cookies
 */
export const removeMenuInfo = (): void => {
  cookies.remove(COOKIE_MENU_ID, { path: '/' });
  cookies.remove(COOKIE_MENU_ITEM_ID, { path: '/' });
};

/**
 * Get cookies value for key provided
 * @param key
 */
export const getValue = (key: string): ParamValue => {
  return cookies.get(key);
};

/**
 * Removes cookies for key provided
 * @param key
 */
export const removeValue = (key: string) => {
  cookies.remove(key, { path: '/' });
};

/**
 * Sets value in cookies for key provided. Can optionally provided a time value to indicate when the stored cookie should expire
 * @param key
 * @param value
 * @param expiresIn number of hours the cookie will expire in. If not provided, will not be included.
 */
export const setValue = (key: string, value: string | number, expiresIn?: number) => {
  const date = new Date();
  if (expiresIn) {
    date.setHours(date.getHours() + expiresIn); // Set expiration time to X hours from now
  }
  cookies.set(key, value, { path: '/', ...(expiresIn && { expires: date } ) });
};
