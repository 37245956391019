import axios, { AxiosRequestConfig } from 'axios';
import { ENDPOINTS_TO_INCLUDE } from '../constants/endpoints';
import Cookies from 'universal-cookie/cjs';
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from '../constants/cookie-constants';
import { sessionRefresh } from './auth';
import { storeAccessToken, storeRefreshToken } from '../utils/auth';
import { eventEmitter } from '../utils/events';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

export const mobileApiClient = axios.create({
  baseURL: process.env.REACT_APP_MOBILE_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

const cookies = new Cookies();

mobileApiClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const shouldIncludeToken = ENDPOINTS_TO_INCLUDE.some((url) => {
    return url === config?.url || config?.url?.match(url)
  });

  if (shouldIncludeToken) {
    // Get JWT token from cookies
    const token: string = cookies.get(COOKIE_ACCESS_TOKEN);

    // If token exists, add it to the authorization header
    if (token) {
      config.headers['Authorization'] = token;
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});


const retryCounts = new Map<string, number>();

mobileApiClient.interceptors.response.use(undefined, async (error) => {
  // Reject promise if usual error
  if (error?.response?.status !== 401) {
    return Promise.reject(error);
  }

  // Don't retry more than 2 times
  const config = error.config;

  const url = config.url;
  const retryCount = retryCounts.get(url) || 0;

  if (retryCount >= 2) {
    retryCounts.delete(url);
    cookies.remove(COOKIE_REFRESH_TOKEN, { path: '/' });
    eventEmitter.emit('token_refresh_failed');
    return Promise.reject(error);
  }

  retryCounts.set(url, retryCount + 1);


  // Attempt to refresh token
  try {
    const token: string = cookies.get(COOKIE_REFRESH_TOKEN);

    if (token) {
      const { accessToken, refreshToken } = await sessionRefresh(token).catch((err) => {
        throw err;
      });

      // Update tokens in cookies
      storeAccessToken(accessToken);
      storeRefreshToken(refreshToken);

      // Retry original request
      config.headers['Authorization'] = accessToken;
      config.retryCount += 1;

      return new Promise((resolve, reject) => {
        mobileApiClient.request(config).then(response => {
          resolve(response);
        }).catch((err) => {
          reject(err);
        })
      });
    } else {
      return Promise.reject(error);
    }
  } catch (error) {
    // If error while refreshing tokens, reject promise
    return Promise.reject(error);
  }
});
