import React from 'react';

export const TapTabWhiteIcon = ({ width = "13", height = "13", className = "", fill = "white" }) => {

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.138 0C54.29 0 69.8158 15.4329 69.8158 34.4704C69.8158 52.9636 55.1651 68.0554 36.7715 68.9032C36.5688 65.4392 36.5746 62.8764 36.7885 61.2147C37.0929 58.8502 38.0315 56.3262 38.6177 55.2735C40.4497 51.9836 45.1959 50.4502 44.8336 37.345C43.7933 25.4522 43.5535 28.0507 42.3092 19.4743C41.429 13.5027 41.6925 11.6286 40.9833 11.6286C40.4959 11.6286 39.8304 21.763 40.1472 31.7168C40.261 36.0073 40.1472 37.7942 39.1674 37.7942C38.1877 37.7942 38.3119 36.0205 38.3119 31.7168L38.3118 31.445C38.3066 26.788 38.1662 11.6286 37.1121 11.6286C36.0365 11.6286 35.8171 25.4808 35.8171 30.4572L35.8173 30.6631C35.8251 35.4988 36.1015 37.7942 35.0259 37.7942C33.9352 37.7942 34.0238 35.4337 34.0238 30.4343C34.0238 17.7185 33.4455 11.6286 32.8106 11.6286C31.7104 11.6286 31.8297 24.5808 31.8349 30.1271L31.8352 30.6536C31.8404 35.8485 32.0464 37.7942 30.8492 37.7942C29.637 37.7942 29.8707 35.8855 29.8707 30.4572L29.8705 30.0846C29.8628 17.8486 29.4724 11.7361 28.9647 11.7361C28.0545 11.7361 28.1818 15.5838 27.5236 20.1976C26.8655 24.8114 25.7829 26.7863 25.2458 38.0291C24.7087 49.2719 28.8769 51.4949 31.4165 55.2735C32.7414 57.8424 32.9272 58.3769 33.2554 61.2147C33.4742 63.1066 33.4742 65.7661 33.2554 69.1932L33.2742 68.8918C14.9888 67.9292 0.460205 52.8863 0.460205 34.4704C0.460205 15.4329 15.986 0 35.138 0Z" fill={fill} />
    </svg>
  );
};

export default TapTabWhiteIcon;
