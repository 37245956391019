/**
 * Helper function used to determine className for menu item name
 * Determines size of name based on length.
 * @param name
 * @return {string} - CSS class rule
 */
export const computeMenuItemName = (name) => {
  if (name?.length > 60) {
    return 'small';
  } else if (name?.length <= 60 && name?.length > 30) {
    return 'medium';
  } else {
    return 'large';
  }
};

export const mediaValueMapper = ({ media, key, fallback }) => {
  let _media;
  if (Array.isArray(media)) {
    _media = media?.[0] || "";
  } else {
    _media = media || "";
  }

  if (_media?.thumbnail?.thumbnailURL) {
    return _media?.thumbnail?.thumbnailURL;
  }
  
  if (_media) {
    return _media?.[key] || _media?.[fallback];
  }

  return _media;
};

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const validateEmail = (email, optional = false) => {
  const validateEmail = (value) => {
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return value.match(mailFormat);
  };

  if (email.trim() === '' && !optional) {
    return 'email_sign_up_required_error';
  }

  if (email.trim() !== '' && !validateEmail(email)) {
    return 'email_sign_up_format_error';
  }
};

export const buildRestaurantPath = (restaurantUrlID, menuID, menuItemID) => {
  let path = '';

  if (restaurantUrlID) {
    path = `/${restaurantUrlID}`;
    if (menuID) {
      path += `/${menuID}`;
      if (menuItemID) {
        path += `/${menuItemID}`;
      }
    }
  }

  return path;
};

export const camelToSnakeCase = (str) => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const kebabToSnake = (kebabStr) => {
  // Replace all hyphens with underscores
  return kebabStr?.replace(/-/g, '_');
};
