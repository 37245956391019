import { i18n } from 'i18next';
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from '../constants/cookie-constants';
import Cookies from 'universal-cookie';


const cookies = new Cookies();

/**
 * Utility function that validates a password when being created
 * Ensure password constraints:
 *   - Min length of 8
 *   - at least 1 lowercase char
 *   - at least 1 uppercase char
 *   - at least 1 integer
 *   - at least 1 special char
 * @param password - value being validated
 * @param translate - i18n translation instance - translates to user's locale if provided, English if not
 * @return (boolean | string) true if valid password, a validation message if password is invalid
 */
export const validatePassword = (password: string, translate?: i18n): boolean | string => {
  const hasUppercase = /[A-Z]+/.test(password);
  const hasLowercase = /[a-z]+/.test(password);
  const hasNumber = /[0-9]+/.test(password);
  const hasSpecialChar = /[^A-Za-z0-9]+/.test(password);

  if (!hasUppercase) {
    if (translate) {
      return translate?.t('pwd_constraint_one_upper_char').toString();
    }

    return 'Password must have at least one uppercase letter.';
  }

  if (!hasLowercase) {
    if (translate) {
      return translate?.t('pwd_constraint_one_lower_char').toString();
    }

    return 'Password must have at least one lowercase letter.';
  }

  if (!hasNumber) {
    if (translate) {
      return translate?.t('pwd_constraint_one_int').toString();
    }

    return 'Password must have at least one number.';
  }

  if (!hasSpecialChar) {
    if (translate) {
      return translate?.t('pwd_constraint_one_special_char').toString();
    }

    return 'Password must have at least one special character.';
  }

  return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
};

export const storeAccessToken = (token: string): void => {
  const date = new Date();
  date.setHours(date.getHours() + 3); // Set expiration time to 3 hours from now

  cookies.set(COOKIE_ACCESS_TOKEN, token, { path: '/', expires: date });
};

export const storeRefreshToken = (token: string): void => {
  const date = new Date();
  date.setDate(date.getDate() + 7); // Set expiration time to 7 days from now

  cookies.set(COOKIE_REFRESH_TOKEN, token, { path: '/', expires: date });
};
