import { useCallback, useEffect, useState } from 'react';

// Defines the status of script loading or error state
export type ScriptStatus = 'idle' | 'loading' | 'loaded' | 'error';

const cachedScripts: string[] = [];

/**
 * Loads dynamic hooks which allows us to cache scripts and utilize functionality like 'locales'
 */
const useScript = (): [ScriptStatus, (source: string) => void] => {
  const [status, setStatus] = useState<ScriptStatus>('idle');
  const [src, setSrc] = useState<string | null>(null);

  const loadScript = useCallback((source: string) => {
    setSrc(source);
  }, []);

  useEffect(() => {
    if (src === null) {
      return;
    }

    // If script cached then it already exists, no need to load again
    if (cachedScripts.includes(src)) {
      setStatus('loaded');
    } else {
      setStatus('loading');

      const script = document.createElement('script');
      script.src = src;
      script.async = true;

      const onScriptLoad = () => {
        cachedScripts.push(src);
        setStatus('loaded');
      };

      const onScriptError = () => {
        // remove from cached scripts to attempt another load
        const idx = cachedScripts.indexOf(src);
        if (idx >= 0) {
          cachedScripts.splice(idx, 1);
        }

        script.remove();
        setStatus('error');
      }

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      // add script to document body
      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src]);

  return [status, loadScript];
};

export default useScript;
